'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaIncidentTracking.factory:CommentsListFactory

 # @description

###
class CommentsListFactory
  ### @ngInject ###
  constructor: (
    $mdMedia
    $mdDialog
    $log
    Incidents
    RestUtils
    Restangular
    # IntercadIncidentComments
  ) ->
    CommentsListBase = {}
    CommentsListBase.showIncidentComments = (incidentId, options = {} ) ->

      url = 'lpa/incidents/' + incidentId + '/logs'
      factory = Restangular.service(url)

      IncidentComments = RestUtils.getFullList factory,
        sort: 'createdAt,ASC'

      defaults =
        allowCancel: true,
        title: "app.comments",
        fullScreen: "auto",
        useFullScreen: ($mdMedia('sm') || $mdMedia('xs')),
        onCancel: ()->
          $log.debug "Closed dialog"

      options = angular.extend {}, defaults, options

      $mdDialog.show
        controllerAs: 'commentsList',
        resolve:
          options: () ->
            options
          title: () ->
            options.title
          comments: ()->
            IncidentComments

        controller: [
          '$scope'
          '$mdDialog'
          'options'
          'comments'
          'mundoConfiguration'
          'UserPermissions'
          ($scope, $mdDialog, options, comments, mundoConfiguration, UserPermissions) ->
            $scope.options = options
            $scope.title = options.title
            $scope.comments = comments
            $scope.newComment = {}
            $scope.hide = () ->
              $mdDialog.hide()

            $scope.cancel = () ->
              $mdDialog.cancel()

            $scope.dispatchFeatures = mundoConfiguration.dispatchFeatures
            $scope.commentPermission = UserPermissions.check 'allow adding incident comments'
            
            $scope.addIncidentComment = () ->
              $scope.newComment.code = 'COMMENT'
        
              # opted out not to use intercad comment, simple CRUD
              # IntercadIncidentComments.newIntercadIncidentComment(incidentId, $scope.newComment)
              Restangular.all(url).post($scope.newComment)
              .then (result) ->
                $scope.comments.push result
                $scope.newComment = {}
            
            $scope.removeIncidentComment = (comment) ->
              Restangular.all(url).one(comment.id).remove()
              .then (result) ->
                $scope.comments.splice($scope.comments.indexOf(comment), 1)

        ]
        templateUrl: 'lpa/lpa-incident-tracking/views/lpa-incident-comments-list.tpl.html'
        parent: angular.element(document.body)
        clickOutsideToClose: true
        fullscreen: false
      .then (result) ->
        options.onCancel()
      , () ->
        return options.onCancel()
        # Cancel

    CommentsListBase.showPOIOrderComments = (poiOrderId, options = {} ) ->

      url = 'lpa/poi_orders/' + poiOrderId + '/logs'
      factory = Restangular.service(url)

      POIOrderComments = RestUtils.getFullList factory,
        sort: 'createdAt,ASC'

      defaults =
        allowCancel: true,
        title: "app.comments",
        fullScreen: "auto",
        useFullScreen: ($mdMedia('sm') || $mdMedia('xs')),
        onCancel: ()->
          $log.debug "Closed dialog"

      options = angular.extend {}, defaults, options

      $mdDialog.show
        controllerAs: 'commentsList',
        resolve:
          options: () ->
            options
          title: () ->
            options.title
          comments: ()->
            POIOrderComments

        controller: [
          '$scope'
          '$mdDialog'
          'options'
          'comments'
          'mundoConfiguration'
          'UserPermissions'
          ($scope, $mdDialog, options, comments, mundoConfiguration, UserPermissions) ->
            $scope.options = options
            $scope.title = options.title
            $scope.comments = comments
            $scope.newComment = {}
            $scope.hide = () ->
              $mdDialog.hide()

            $scope.cancel = () ->
              $mdDialog.cancel()
            
            $scope.dispatchFeatures = mundoConfiguration.dispatchFeatures
            $scope.commentPermission = UserPermissions.check 'allow adding poi order comments'
            
            $scope.addPOIOrderComment = () ->
              $scope.newComment.code = 'COMMENT'
        
              Restangular.all(url).post($scope.newComment)
              .then (result) ->
                $scope.comments.push result
                $scope.newComment = {}

        ]
        templateUrl: 'lpa/lpa-poi-order-tracking/views/lpa-poi-order-comments-list.tpl.html'
        parent: angular.element(document.body)
        clickOutsideToClose: true
        fullscreen: false
      .then (result) ->
        options.onCancel()
      , () ->
        return options.onCancel()
        # Cancel

    return CommentsListBase

angular
  .module 'lpaIncidentTracking'
  .factory 'CommentsList', CommentsListFactory
